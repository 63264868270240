export default {
  success: true,
  projects: [
    {
      item: "Personal Website",
      description: [
        "This website is the project and live demo!",
        "Frontend: ",
        "It is written from scratch with no templates, only with HTML, CSS and JS with the React framework.",
        "The site is hosted on AWS Amplify and using AWS Route53 for DNS routing.",
        "Backend: ",
        "It is a severless architecture where there is no servers to maintain. The lambda function uses python for reading, storing and updating to the DB.",
        "It uses API Gateway, Lambda Function and DynamoDB.",
        "My overall goal was to deploy a website to the internet. There are many ways to deploy a website but I choose the AWS serverless route since I was studying for certifcations.",
      ],
      tech: [
        "VisualCode",
        "React",
        "JavaScript",
        "Html",
        "Css",
        "Git",
        "Github",
        "Python",
      ],
      aws: ["Amplify", "Route53", "DynamoDB", "API Gateway", "Lambda"],
      url: "https://www.vectorlogo.zone/logos/python/python-ar21.svg",
    },
    {
      item: "Cloud Resume Challenge",
      description: [
        "The resume tab on this website is the project and live demo!",
        "Frontend: ",
        "Written in HTML, CSS and JS.",
        "The resume is hosted on a S3 bucket, it is set to public but is configured with strict IAM (Identity and Access Management), bucket and object policies.",
        "Backend: ",
        "It is a severless architecture where there is no servers to maintain. The lambda function uses python for reading, storing and updating to the DB.",
        "It uses API Gateway, Lambda Function and DynamoDB.",
        "My overall goal was learn about AWS policies while studying for AWS certifications.",
      ],
      tech: [
        "Html",
        "Css",
        "JavaScript",
        "Python",
        "VisualCode",
        "Git",
        "Github",
      ],
      aws: ["S3", "Amplify", "Route53", "DynamoDB", "API Gateway", "Lambda"],
      url: "https://www.vectorlogo.zone/logos/javascript/javascript-ar21.svg",
    },
    {
      item: "EZMonitor",
      description: [
        "The project is under construction. It is a work in progress with code on GitHub and no live demo.",
      ],
      tech: [],
      aws: [],
      url: "https://www.vectorlogo.zone/logos/javascript/javascript-ar21.svg",
    },
    {
      item: "CloudLab Guide",
      description: [
        "The project is under construction. It is a work in progress with code on GitHub and no live demo.",
      ],
      tech: [],
      aws: [],
      url: "https://www.vectorlogo.zone/logos/javascript/javascript-ar21.svg",
    },
  ],
};
